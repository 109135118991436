/**
 * Foursource page
 * https://foursource.com/about-us
 */

import * as React from 'react';
import { withTrans } from '../i18n/withTrans';

// components
import Layout from '../components/layout';

class forSuppliersPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    return (
      <>
        <Layout pageName='for-suppliers' whiteMenu={false}></Layout>
      </>
    );
  }
}

export default withTrans(forSuppliersPage);
